module.exports = {
  defaultTitle: 'Asif Amjad - IT professional',
  logo: 'https://portfolio.smakosh.com/favicon/favicon-512.png',
  author: 'Asif Amjad',
  url: 'https://amjad-a.com',
  legalName: 'Asif Amjad',
  defaultDescription: 'I’m Asif and I’m a Backend & Devops engineer!',
  socialLinks: {
    github: 'https://github.com/asifamjad',
    linkedin: 'https://www.linkedin.com/in/asif.amjad/',
  },
  //googleAnalyticsID: '348379165',
  googleAnalyticsID: 'G-5ZTJJYT238',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'appId',
    twitter: '@aamjad',
  },
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'asif.amjad@gmail.com',
    phone: 'phone number',
  },
  foundingDate: '2008',
};
